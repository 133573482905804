import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'

import { useQueryCheckIsOnboarded } from '../../api/auth/auth'

export const useCheckIsOnboarded = () => {
  const location = useLocation()
  const [isError, setIsError] = useState(false)
  const { cid } = parse(location?.search)

  const { data, isError: isErrorCheck, isSuccess, isLoading } = useQueryCheckIsOnboarded(cid)

  useEffect(() => {
    // if (data?.data?.instanceUrl) return (window.location.href = data?.data.instanceUrl)
    if (isSuccess) {
      if (!data?.data?.isSuccess) return setIsError(true)
    } else if (isErrorCheck) {
      setIsError(true)
    }
  }, [isSuccess, data, isErrorCheck])

  return { isLoading, isError, data: data?.data }
}
