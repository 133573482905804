import { useState, useEffect } from 'react'
import { Steps, Layout, Row, Col, Typography, Space, Spin } from 'antd'
import { Switch, Redirect, Route } from 'react-router-dom'

import { useCheckIsOnboarded } from './common/hooks'

import './App.scss'
import './components/Steps/Steps.scss'
import FirstStep from './components/Steps/FirstStep/FirstStep'
import SecondStep from './components/Steps/SecondStep/SecondStep'
import ThirdStep from './components/Steps/ThirdStep/ThirdStep'

import Logo from './app/assets/Logo.png'
import OnboardingStep1 from './app/assets/OnboardingStep1.png'
import OnboardingStep2 from './app/assets/OnboardingStep2.png'
import OnboardingStep3 from './app/assets/OnboardingStep3.png'
import { grid, STATUSES_STEPS } from './constants'

const { Step } = Steps
const { Content } = Layout
const { Title } = Typography

const steps = [
  {
    step: 0,
    title: 'Шаг 1',
    content: ({ handleNext, customerData }) => (
      <FirstStep onNext={handleNext} customerData={customerData} />
    ),
    icon: <img className="step-logo" src={OnboardingStep1} alt={OnboardingStep1} />
  },
  {
    step: 1,
    title: 'Шаг 2',
    content: ({ handleNext, customerData }) => (
      <SecondStep onNext={handleNext} customerData={customerData} />
    ),
    icon: <img className="step-logo" src={OnboardingStep2} alt={OnboardingStep2} />
  },
  {
    step: 2,
    title: 'Шаг 3',
    content: ({ handleBack, customerData }) => (
      <ThirdStep onBack={handleBack} customerData={customerData} />
    ),
    icon: <img className="step-logo" src={OnboardingStep3} alt={OnboardingStep3} />
  }
]

function App() {
  const [current, setCurrent] = useState(0)
  const [customerData, setCustomerData] = useState(0)
  const { isLoading, isError, data } = useCheckIsOnboarded()
  const handleNext = () => setCurrent(current + 1)
  const handleBack = () => setCurrent(current - 1)

  useEffect(() => {
    if (data) {
      setCurrent(STATUSES_STEPS?.[data?.status])
      setCustomerData(data)
    }
  }, [data])

  if (isError)
    return (
      <Space className="loader" size="middle">
        <Title>Ошибка</Title>
      </Space>
    )

  if (isLoading)
    return (
      <Space className="loader" size="middle">
        <Spin size="large" />
      </Space>
    )

  return (
    <Switch>
      <Route exact path="/onboarding">
        <div className="App">
          <Layout>
            <Row>
              <div className="logo">
                <img className="logo-image" src={Logo} alt={Logo} />
              </div>
            </Row>
            <Content className="wrapper">
              <Row>
                <Col className="mt15" {...grid.sideBar}>
                  <Steps direction="vertical" current={current}>
                    {steps.map(item => (
                      <Step
                        icon={current <= item.step && item.icon}
                        key={item.step}
                        title={item.title}
                      />
                    ))}
                  </Steps>
                </Col>
                <Col {...grid.content}>
                  {steps[current].content({ handleNext, handleBack, customerData })}
                </Col>
              </Row>
            </Content>
          </Layout>
        </div>
      </Route>
      <Route path="/">
        <Redirect to="/onboarding" />
      </Route>
    </Switch>
  )
}

export default App
