import { Col } from 'antd'
import Finish from './Finish'

export default function ThirdStep({ customerData }) {
  return (
    <Col className="ThirdStep">
      <Finish customerData={customerData} />
    </Col>
  )
}
