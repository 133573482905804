import { useMutation, useQuery } from 'react-query'

import { api, queryClient } from '../api'
export const START = 'start'
export const updateStartQueryOptions = {
  onSuccess: data => {
    queryClient.setQueryData([START, data?.data?.customerId], data)
  }
}
const fetchSendCodeOnPhone = ({ mobilePhone, recapthaResponse }) => {
  return api.post({ path: `customer/phone`, data: { phone: mobilePhone, recapthaResponse } })
}

const fetchVerify = ({ code }) => {
  return api.post({ path: `customer/phone/verify`, data: { code } })
}

const fetchCheckIsOnboarded = ({ queryKey }) => {
  const [, /* key */ cid] = queryKey
  return api.post({ path: `start?cid=${cid}` })
}

export function useMutateSendCodeOnPhone(options) {
  return useMutation(fetchSendCodeOnPhone, options)
}

export function useMutateVerify(options) {
  return useMutation(fetchVerify, options)
}
export function useQueryCheckIsOnboarded(cid, options) {
  return useQuery([START, cid], fetchCheckIsOnboarded, options)
}
