import { useEffect, useState } from 'react'
import { Row } from 'antd'
import Auth from './Auth'
import Verify from './Verify'
import { useCheckIsOnboarded } from '../../../common/hooks'
import { STATUSES } from '../../../constants'

export default function FirstStep({ onNext }) {
  const [wasCodeSend, setWasCodeSend] = useState(false)
  const { data } = useCheckIsOnboarded()

  useEffect(() => {
    if (data) {
      setWasCodeSend(data?.status === STATUSES.phoneConfirmation)
    }
  }, [data])

  return (
    <Row justify="center FirstStep">
      {wasCodeSend ? (
        <Verify
          onSuccess={onNext}
          phone={data?.customerInfo?.phone}
          onBack={() => setWasCodeSend(false)}
        />
      ) : (
        <Auth onSuccess={() => setWasCodeSend(true)} phone={data?.customerInfo?.phone} />
      )}
    </Row>
  )
}
