import { Button, Col, Input, Row, Typography, message } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import OnboardingPhoneCode from '../../../app/assets/OnboardingPhoneCode.png'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import InputMask from 'react-input-mask'

import { useMutateSendCodeOnPhone } from '../../../api/auth/auth'
import { useEffect } from 'react'

const { Title, Text } = Typography

const defaultValues = {
  phone: ''
}

const schema = yup.object().shape({
  phone: yup
    .string()
    .required('Пожалуйста, введите номер телефона')
    // .matches(phoneRegExp, "Please, fill in the phone number")
    .nullable()
})

export default function Auth({ onSuccess, phone = '' }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({ defaultValues, resolver: yupResolver(schema) })

  useEffect(() => {
    setValue('phone', phone)
  }, [phone, setValue])

  const { mutate: sendCodeOnPhone, isLoading: isLoadingSendCodeOnPhone } = useMutateSendCodeOnPhone(
    {
      onSuccess: ({ data }) => {
        if (data.isSuccess) {
          return onSuccess()
        }
        return message.error('Ошибка. Код не был отправлен на Ваш телефон')
      },
      onError: () => message.error('Ошибка. Код не был отправлен на Ваш телефон')
    }
  )

  const handleFinishAuthForm = handleSubmit(values => {
    const mobilePhone = values?.phone?.split(' ').join('')
    sendCodeOnPhone({ mobilePhone, recapthaResponse: '0.5' })
  })

  return (
    <Col className="Auth">
      <Row justify="center">
        <img
          className="step-image step-1-image"
          src={OnboardingPhoneCode}
          alt={OnboardingPhoneCode}
        />
      </Row>
      <Row justify="center">
        <Title className="title">Двухфакторная аутентификация</Title>
      </Row>
      <Row justify="center">
        <Text className="subtitle">
          Это необходимо, чтобы убедиться, что в систему входите действительно вы, или связаться с
          вами, если мы заметим подозрительную активность в вашей учетной записи
        </Text>
      </Row>
      <Row justify="center">
        <Text className="label">Укажите свой телефон</Text>
      </Row>
      <Row justify="center">
        <Controller
          name="phone"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputMask
              mask="+7 999 999 99 99"
              maskChar="-"
              onChange={onChange}
              value={value}
              disabled={isLoadingSendCodeOnPhone}
            >
              {inputProps => <Input {...inputProps} size="large" type="tel" />}
            </InputMask>
          )}
        />
        {errors.phone?.message && (
          <Row>
            <Text style={{ color: 'red' }} className="text-center">
              {errors.phone.message}
            </Text>
          </Row>
        )}
      </Row>

      <Row justify="center">
        <Button
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={isLoadingSendCodeOnPhone}
          className="mb15"
          onClick={handleFinishAuthForm}
        >
          Дальше
        </Button>
      </Row>

      <Row justify="center">
        <Text className="footer-text">
          Ваш номер телефона будет использоваться в целях безопасности, например, чтобы помочь вам
          восстановить пароль, если вы его забудете
        </Text>
      </Row>
    </Col>
  )
}
