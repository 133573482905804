import { Button, Col, Input, Row, Typography, message } from 'antd'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import OnboardingPhoneCode from '../../../app/assets/OnboardingPhoneCode.png'

import { useMutateVerify } from '../../../api/auth/auth'
import { useMutateCustomerGoBack } from '../../../api/customer/customer'
const { Title, Text } = Typography

const defaultValues = {
  code1: '',
  code2: '',
  code3: '',
  code4: ''
}

const schema = yup.object().shape({
  code1: yup.string().required('Please, enter the code').nullable().min(1).max(1),
  code2: yup.string().required('Please, enter the code').nullable().min(1).max(1),
  code3: yup.string().required('Please, enter the code').nullable().min(1).max(1),
  code4: yup.string().required('Please, enter the code').nullable().min(1).max(1)
})

export default function Verify({ onBack, onSuccess, phone }) {
  const code1Ref = useRef()
  const code2Ref = useRef()
  const code3Ref = useRef()
  const code4Ref = useRef()
  // const submitBTNRef = useRef()
  const {
    mutate: mutateGoBack,
    isLoading: isLoadingGoBack,
    isSuccess: isSuccessGoBack,
    isError: isErrorGoBack
  } = useMutateCustomerGoBack()

  useEffect(() => {
    if (isSuccessGoBack) {
      console.log()
    } else if (isErrorGoBack) {
      message.error('Ошибка. Невозможно перейти на предыдущий шаг')
    }
  }, [isErrorGoBack, isSuccessGoBack])

  const handleBack = () => {
    mutateGoBack()
    onBack()
  }
  const codes = [
    {
      name: 'code1',
      ref: code1Ref,
      nextRef: code2Ref
    },
    {
      name: 'code2',
      ref: code2Ref,
      nextRef: code3Ref
    },
    {
      name: 'code3',
      ref: code3Ref,
      nextRef: code4Ref
    },
    {
      name: 'code4',
      ref: code4Ref
    }
  ]

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ defaultValues, resolver: yupResolver(schema) })

  const handleFinishVerifyForm = handleSubmit(values => {
    const { code1, code2, code3, code4 } = values
    const code = code1 + code2 + code3 + code4
    verify({ code })
  })

  const { mutate: verify, isLoading: isLoadingVerify } = useMutateVerify({
    onSuccess: ({ data }) => {
      if (data.isSuccess) {
        return onSuccess()
      }
      return message.error('Ошибка. Не правильный код')
    },
    onError: () => message.error('Ошибка. Не правильный код')
  })

  useLayoutEffect(() => {
    code1Ref?.current?.focus()
  }, [])

  return (
    <Col className="Verify">
      <Row>
        <img
          className="step-image step-1-image"
          src={OnboardingPhoneCode}
          alt={OnboardingPhoneCode}
        />
      </Row>
      <Row justify="center">
        <Title className="title" level={2}>
          Введите код верификации
        </Title>
      </Row>
      <Row justify="center">
        <Text className="subtitle">
          Код верификации был отправлен вам на номер ******{phone?.slice(-4)}
        </Text>
      </Row>
      <Row justify="center">
        <Text className="label">Введите код здесь:</Text>
      </Row>
      <Row justify="center">
        {codes.map(code => (
          <Controller
            key={code.name}
            name={code.name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                onChange={e => {
                  const value = e?.target?.value
                  // вставляем скопированный код
                  if (value?.length > 1) {
                    if (value?.length !== 4 && code.name !== codes[0].name) {
                      onChange('')
                      return null
                    }
                    const [code1, code2, code3, code4] = value.split('')
                    reset({ code1, code2, code3, code4 })
                    return handleFinishVerifyForm()
                  }
                  onChange(e)
                  if (code.nextRef) {
                    code.nextRef.current?.focus()
                  } else {
                    handleFinishVerifyForm()
                  }
                }}
                value={value}
                ref={code.ref}
                size="large"
                className="decimal-input"
              />
            )}
          />
        ))}
      </Row>
      {(errors.code1 || errors.code2 || errors.code3 || errors.code4) && (
        <Row justify="center">
          <Text type="danger" className="text-center">
            Пожалуйста, введите код верификации
          </Text>
        </Row>
      )}
      <Row justify="center">
        <Button
          className="retry-link"
          block
          type="link"
          onClick={handleBack}
          loading={isLoadingGoBack}
          disabled={isLoadingVerify}
        >
          Не получили код верификации?
        </Button>
      </Row>
    </Col>
  )
}
