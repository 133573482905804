import { Button, Col, Divider, message, Typography, Select, Row } from 'antd'
import { useEffect } from 'react'
import { useMutateBusiness } from '../../../api/customer/customer'
import { useForm, Controller } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
const { Option } = Select
import OnboardingChooseYourBusiness from '../../../app/assets/OnboardingChooseYourBusiness.png'

const { Title, Text } = Typography
const businessList = [
  { id: 'Ресторан', name: 'Ресторан' },
  { id: 'Магазин', name: 'Магазин' },
  { id: 'Пекарня', name: 'Пекарня' },
  { id: 'Кафе', name: 'Кафе' },
  { id: 'Ларек', name: 'Ларек' },
  { id: 'Кофейня', name: 'Кофейня' },
  { id: 'Склад', name: 'Склад' },
  { id: 'Салон', name: 'Салон' },
  { id: 'Отделение', name: 'Отделение' },
  { id: 'Пункт', name: 'Пункт' },
  { id: 'Столовая', name: 'Столовая' },
  { id: 'Бар', name: 'Бар' },
  { id: 'Кинотеатр', name: 'Кинотеатр' },
  { id: 'Точка', name: 'Точка' }
]

const defaultValues = {
  businessType: null
  // otherBusinessType: ''
}

export const lastOption = businessList[businessList?.length - 1]

const schema = yup.object().shape({
  businessType: yup.string().required('Пожалуйста, выберите Ваше подразделение').nullable()
  // нужно для otherBusinessType
  // otherBusinessType: yup.string().when('businessType', {
  //   is: lastOption?.id,
  //   then: yup.string().required('Введите другой тип бизнеса.')
  // })
})

export default function SecondStep({ onNext, customerData }) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ defaultValues, resolver: yupResolver(schema) })

  useEffect(() => {
    if (customerData) {
      // нужно для otherBusinessType
      // if (customerData.customerInfo.businessTypeId === lastOption.id) {
      //   reset({
      //     businessType: customerData.customerInfo.businessTypeId,
      //     otherBusinessType: customerData.customerInfo.businessTypeText
      //   })
      // } else {
      reset({ businessType: customerData.customerInfo.businessTypeId })
      // }
    }
  }, [customerData, reset])

  const {
    mutate: sendBusiness,
    isLoading: isLoadingSendBusiness,
    isError: isErrorSendBusiness,
    isSuccess: isSuccessSendBusiness
  } = useMutateBusiness()

  useEffect(() => {
    if (isSuccessSendBusiness) {
      return onNext()
    } else if (isErrorSendBusiness) {
      message.error('Ошибка. Бизнес не был сохранен')
    }
  }, [onNext, isErrorSendBusiness, isSuccessSendBusiness])

  const handleFinishAuthForm = handleSubmit(values => {
    const { businessType } = values
    const businessTypeText = businessList.find(({ id }) => id === businessType)?.name
    sendBusiness({ businessTypeId: businessType, businessTypeText })
  })

  return (
    <Col className="SecondStep">
      <Row justify="center">
        <img
          className="step-image step-2-image"
          src={OnboardingChooseYourBusiness}
          alt={OnboardingChooseYourBusiness}
        />
      </Row>
      <Title className="title" level={2}>
        Выберите формат ваших подразделений
      </Title>

      <Row justify="center">
        <Controller
          name="businessType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select onChange={onChange} defaultValue={value} className="business-select">
              {businessList.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
        />
      </Row>

      {errors?.businessType?.message && (
        <Row justify="center" className="mt15">
          <Text className="text-center" type="danger">
            {errors.businessType.message}
          </Text>
        </Row>
      )}

      <Row justify="center">
        <div className="divider">
          <Divider />
        </div>
      </Row>

      <Row justify="center">
        <Button
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={isLoadingSendBusiness}
          onClick={handleFinishAuthForm}
        >
          Дальше
        </Button>
      </Row>
    </Col>
  )
}
