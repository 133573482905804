export const threeMinutes = 3 * 60 * 1000

export const STATUSES = {
  phoneSelection: 'PhoneSelection',
  phoneConfirmation: 'PhoneConfirmation',
  businessTypeSelection: 'BusinessTypeSelection',
  forecastingSelection: 'ForecastingSelection',
  filled: 'Filled'
}

export const STATUSES_STEPS = {
  [STATUSES.phoneSelection]: 0,
  [STATUSES.phoneConfirmation]: 0,
  [STATUSES.businessTypeSelection]: 1,
  [STATUSES.forecastingSelection]: 2,
  [STATUSES.filled]: 2
}
export const grid = {
  sideBar: {
    xs: {
      span: 1
    },
    sm: {
      span: 4
    }
  },
  content: {
    xs: {
      span: 19,
      offset: 1,
      pull: 1
    },
    sm: {
      span: 16,
      offset: 4,
      pull: 4
    }
  }
}

export const STATUS_FILLED = 'Filled'
export const TENANT_STATUS_NONE = 'None'
export const TENANT_STATUS_READY = 'Ready'
