import { useMutation, useQuery } from 'react-query'

import { api } from '../api'
import { updateStartQueryOptions } from '../auth/auth'
const STATUS = 'status'

const fetchStatus = () => {
  return api.get({ path: 'customer/status' })
}
const fetchBusiness = data => {
  return api.post({ path: 'customer/business', data })
}

const fetchCustomerGoBack = data => {
  return api.post({ path: 'customer/status/back', data })
}
const fetchCustomerStatus = () => {
  return api.get({ path: 'customer/status' })
}
const fetchForecast = ({ hasForecasting, position }) => {
  return api.post({
    path: 'customer/forecast',
    data: { hasForecasting, position }
  })
}

export const useMutateStatus = options => {
  return useMutation(fetchStatus, options)
}
export const useMutateBusiness = () => {
  return useMutation(fetchBusiness, updateStartQueryOptions)
}
export const useMutateForecast = () => {
  return useMutation(fetchForecast, updateStartQueryOptions)
}
export const useMutateCustomerGoBack = () => {
  return useMutation(fetchCustomerGoBack, updateStartQueryOptions)
}
export const useQueryCustomerStatus = isPendingStarted => {
  return useQuery([STATUS], fetchCustomerStatus, {
    enabled: isPendingStarted,
    refetchInterval: 10000
  })
}
