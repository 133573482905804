import { Button, Col, message, Row, Typography } from 'antd'

import { useQueryCustomerStatus } from '../../../api/customer/customer'

import OnboardingSystem from '../../../app/assets/OnboardingSystem.png'
import { useEffect, useState } from 'react'
import { STATUS_FILLED, TENANT_STATUS_READY } from '../../../constants'

const { Text } = Typography

export default function Finish({ customerData }) {
  const [isStatusNeeded, setIsStatusNeeded] = useState(true)
  useEffect(() => {
    if (
      customerData?.status === STATUS_FILLED &&
      customerData?.data?.tenantStatus === TENANT_STATUS_READY
    ) {
      setIsStatusNeeded(false)
    }
  }, [customerData])

  const { data: customerStatus, isError } = useQueryCustomerStatus(isStatusNeeded)

  useEffect(() => {
    if (isError) {
      message.error('Ошибка.Что-то пошло не так')
    }
  }, [isError])
  return (
    <Col className="Finish">
      <Row justify="center">
        <img className="step-image step-3-image" src={OnboardingSystem} alt={OnboardingSystem} />
      </Row>
      <Row justify="center">
        <Text className="title">Отлично!</Text>
      </Row>
      <Row justify="center">
        <Text className="subtitle">
          Подождите, мы настраиваем Ваш SolvoPro. Этот процесс может занять 10-15 минут. Данную
          вкладку можно закрыть. После завершения настройки вы получите письмо с ссылкой на свой
          личный кабинет и сможете приступить к работе.
        </Text>
      </Row>
      <Row justify="center">
        <Text className="subtitle">
          Если через 15 минут вы не получите письмо - напишите нам в онлайн-чат на сайте.
        </Text>
      </Row>
      <Row justify="center">
        <Button
          block
          className="mt-big"
          size="large"
          type="primary"
          onClick={() => {
            if (customerStatus?.data?.isSuccess && customerStatus?.data?.instanceUrl) {
              message.success('Добро пожаловать')
              window.location.href = customerStatus.data?.instanceUrl
            }
          }}
          loading={!customerStatus || customerStatus.data.tenantStatus !== TENANT_STATUS_READY}
        >
          Начать работу
        </Button>
      </Row>
    </Col>
  )
}
