import axios from 'axios'
import { QueryClient } from 'react-query/core'

import env from '../env'
import { threeMinutes } from '../constants'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: threeMinutes
    }
  }
})

// TODO: for refresh token
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.error('API error:', error)
    return Promise.reject(error)
  }
)

const getHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json'
})

const get = ({ path, data, params }) => makeRequest({ method: 'GET', path, data, params })
const post = ({ path, data, params }) => makeRequest({ method: 'POST', path, data, params })
const put = ({ path, data, params }) => makeRequest({ method: 'PUT', path, data, params })
const patch = ({ path, data, params }) => makeRequest({ method: 'PATCH', path, data, params })
const del = ({ path, data, params }) => makeRequest({ method: 'DELETE', path, data, params })

const makeRequest = ({ method, path, data, params }) =>
  axios.request({
    url: env.apiEndpoint + path,
    method,
    data,
    headers: getHeaders(),
    params
  })

export const api = {
  get,
  post,
  put,
  patch,
  del
}
